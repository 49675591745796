import React, { useContext, useState } from "react";
import burger from "../images/burger.svg";
import styled from "styled-components";
import { Label, FlexWrapper } from "./StyledComponents/StyledComponents";
import { Link, useLocation, useNavigate } from "react-router-dom";
import whiteRight from "../images/whiteRight.svg";
import darkRight from "../images/DarkRight.svg";
import cross from "../images/cross.svg";
import { validURL } from "../Constants/utility";
import { SearchContext } from "../Context/index";
import MainLogo from "../images/defaultLogo.png";
import { lightOrDark, checkImage } from "../Constants/utility";

const HeaderWrapper = styled.header`
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 20px 100px;

  @media screen and (max-width: 1100px) {
    margin: 20px 90px;
  }

  @media screen and (max-width: 800px) {
    margin: 20px 40px;
  }

  @media screen and (max-width: 500px) {
    margin: 20px 24px;
  }

  transition: margin 1s;
`;

const LogoWrapper = styled.img`
  max-width: 160px;
  max-height: 50px;
  cursor: pointer;
`;
const BurgerImage = styled.img`
  max-width: 20px;
  max-height: 20px;
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
`;

const DropDownWrappper = styled.div`
  width: 100%;
  height: 75px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const RightImage = styled.img`
  height: 9.55px;
  width: 5.83px;
`;
const DropDownContent = styled.div`
  width: 85%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: -0.138889px;
  cursor: pointer;
  color: ${(props) => props.color};
`;

const DropDownContainer = styled.div`
  box-sizing: border-box;
  max-height: 150px;
  background-color: ${(props) => props.backColor};
`;

const Modal = styled.div`
  position: fixed; /* Stay in place */
  z-index: 1000; /* Sit on top */
  left: 0;
  top: 95px;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  //overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.5); /* Black w/ opacity */

  @media screen and (max-width: 500px) {
    top: 85px;
  }

  @media (min-width: 769px) {
    display: none;
  }

  transition: top 1s;
`;

const HeaderNavigation = styled.div`
  margin-left: 40px;
`;
const BorderLine = styled.div`
  background: #313c4b;
  border-radius: 9px;
  height: 2px;
  margin-top: 2px;
`;

export default function Header({ bannerData }) {
  const { setSearchInput, setSearchList } = useContext(SearchContext);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const Image_Base_Url = process.env.REACT_APP_IMAGE_BASE_URL;
  const [openDropDown, setOpenDropDown] = useState(false);
  const handleBurgerClick = () => {
    setOpenDropDown(!openDropDown);
  };

  const handleNavigationClicked = (url) => {
    if (validURL(url)) {
      if (url.includes("http://") || url.includes("https://")) {
        window.open(url, "_blank");
      } else {
        let httpUrl = "https://";
        window.open(httpUrl.concat(url), "_blank");
      }
    }
  };

  return (
    <>
      <HeaderWrapper>
        <LogoWrapper
          onClick={() => {
            setSearchInput("");
            setSearchList([]);
            navigate("/");
          }}
          src={
            bannerData
              ? bannerData?.logo === "default"
                ? MainLogo
                : Image_Base_Url + bannerData.logo
              : MainLogo
          }
        />

        <FlexWrapper width="auto" hide align="center" justify="center">
          {bannerData?.enableHelpForm && (
            <HeaderNavigation>
              <Link to={"/submit-issue"} style={{ textDecoration: "none" }}>
                <Label
                  className={
                    pathname === "/submit-issue"
                      ? "headerLabelSelect"
                      : "headerLabel"
                  }
                >
                  {bannerData?.helpPageName}
                </Label>
              </Link>
              {pathname === "/submit-issue" && <BorderLine />}
            </HeaderNavigation>
          )}
          {bannerData?.navigationButtonText && (
            <HeaderNavigation>
              <Label
                onClick={() =>
                  handleNavigationClicked(bannerData?.navigationButtonUrl)
                }
                className="headerLabel"
              >
                {bannerData.navigationButtonText}
              </Label>
            </HeaderNavigation>
          )}
        </FlexWrapper>
        {(bannerData?.enableHelpForm || bannerData?.navigationButtonText) && (
          <BurgerImage
            onClick={handleBurgerClick}
            src={openDropDown ? cross : burger}
          />
        )}
      </HeaderWrapper>
      {openDropDown && (
        <Modal>
          <DropDownContainer
            backColor={
              bannerData ? bannerData?.brandColor : "rgba(20, 115, 230, 1)"
            }
          >
            {bannerData?.enableHelpForm && (
              <DropDownWrappper>
                <DropDownContent
                  color={
                    lightOrDark(bannerData?.brandColor) === "light"
                      ? "#222222"
                      : "#FFFFFF"
                  }
                  onClick={() => navigate("/submit-issue")}
                >
                  Submit an Issue
                  <RightImage
                    src={
                      lightOrDark(bannerData?.brandColor) === "light"
                        ? whiteRight
                        : darkRight
                    }
                  />
                </DropDownContent>
              </DropDownWrappper>
            )}
            {bannerData.navigationButtonText && (
              <>
                <DropDownWrappper
                  onClick={() =>
                    handleNavigationClicked(bannerData?.navigationButtonUrl)
                  }
                >
                  <DropDownContent
                    color={
                      lightOrDark(bannerData?.brandColor) === "light"
                        ? "#222222"
                        : "#FFFFFF"
                    }
                  >
                    {bannerData.navigationButtonText}
                    <RightImage
                      src={
                        lightOrDark(bannerData?.brandColor) === "light"
                          ? whiteRight
                          : darkRight
                      }
                    />
                  </DropDownContent>
                </DropDownWrappper>
              </>
            )}
          </DropDownContainer>
        </Modal>
      )}
    </>
  );
}
