import React, { useContext, useEffect, useState, useRef } from "react";
import DOMPurify from "dompurify";
import styled from "styled-components";
import rightIcon from "../../images/rightIcon.svg";
import "./content-styles.css";
import { useNavigate } from "react-router-dom";
import { ArticleContext } from "../../Context";
import {
  autoCapitaliseFirstLetter,
  scrollToIdOrClass,
} from "../../Constants/utility";

const PreviewArticleContainer = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  padding-left: 100px;
  box-sizing: border-box;

  @media screen and (max-width: 1200px) {
    padding-left: 80px;
  }

  @media screen and (max-width: 1100px) {
    padding-left: 50px;
  }

  @media screen and (max-width: 778px) {
    padding-left: 0px;
  }

  transition: padding 1s;
`;

const BreadCrumContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const BreadCrum = styled.label`
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #313c4b;
  margin-right: 10px;
  cursor: pointer;

  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    text-decoration: underline;
  }
`;

const BreadCrum2 = styled.label`
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #313c4b;
  margin-right: 10px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const RightImage = styled.img`
  height: 9.55px;
  width: 5.83px;
  margin-right: 10px;
`;

const ArticleBody = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  font-family: Inter;
`;

const ArticlePrevWrap = styled.div`
  display: block;
  width: 100%;
`;

export default function PreviewArticle({ categoryTitle, pathname }) {
  const { selectedArticle } = useContext(ArticleContext);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const originalDocTitle = useRef("");

  const navigate = useNavigate();

  //  maintain the order of useEffect
  useEffect(() => {
    setTimeout(() => scrollToIdOrClass(window.location.hash), 1500);
    originalDocTitle.current = document.title;
    return () => {
      document.title = originalDocTitle.current;
    };
  }, []);

  useEffect(() => {
    articleDiv();
    const docTitle = selectedArticle?.title;
    if (!docTitle) return;
    else {
      document.title = autoCapitaliseFirstLetter(docTitle);
    }
  });

  useEffect(() => {
    window.addEventListener("resize", articleDiv);
    return () => {
      window.removeEventListener("resize", articleDiv);
    };
  }, [screenWidth, selectedArticle]);

  const articleDiv = () => {
    const div = document.getElementById("ck");
    if (div) {
      const containerWidth = div.offsetWidth;
      setImageSize(div, containerWidth);
    }
  };

  const setImageSize = (div, containerWidth) => {
    let imgArray = div.querySelectorAll("img");
    imgArray.forEach((image) => {
      if (image.className !== "") {
        if (image.style.width !== "100%") {
          let imageWidth = image?.style?.width?.replace("px", "");
          if (parseFloat(imageWidth) > containerWidth) {
            image.style.width = containerWidth + "px";
          } else {
            image.style.width = imageWidth + "px";
          }
        }
      } else {
        image.style.width = containerWidth + "px";
      }
    });
  };

  function stripHtml(html) {
    let tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    let figureArray = tmp.querySelectorAll("figure");
    figureArray.forEach((figure) => {
      let oembed = figure?.querySelector("oembed");
      figure.style.width = "auto";
      figure.style.height = "auto";
      if (oembed) {
        let url = oembed?.getAttribute("url");
        let videoId = getVedioId(url);
        let ifrm = document.createElement("iframe");
        if (videoId) {
          ifrm?.setAttribute("src", `//www.youtube.com/embed/${videoId}`);
        }
        figure.replaceChildren(ifrm);
      }
    });
    return tmp.innerHTML;
  }

  const getVedioId = (url) => {
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = url.match(regExp);
    if (match && match[2].length == 11) {
      return match[2];
    } else {
      return false;
    }
  };

  //We use this to avoid xss attacks because we get only html data from backend and there is no way to circumvent around this
  // as we need to store everything in html only
  const sanitizedTitle = DOMPurify.sanitize(selectedArticle?.title, {
    ADD_TAGS: ["oembed", "iframe"],
    ADD_ATTR: ["url", "src"],
  });
  const sanitizedContent = DOMPurify.sanitize(selectedArticle?.content, {
    ADD_TAGS: ["oembed", "iframe"],
    ADD_ATTR: ["url", "src"],
  });

  return (
    <PreviewArticleContainer>
      <BreadCrumContainer>
        <BreadCrum2 onClick={() => navigate("/")}>Home</BreadCrum2>
        <RightImage src={rightIcon} alt="righticon" />
        <BreadCrum onClick={() => navigate(`/${pathname?.split("/")[1]}`)}>
          {categoryTitle}
        </BreadCrum>
      </BreadCrumContainer>
      {selectedArticle && Object.keys(selectedArticle).length > 0 ? (
        <ArticleBody id="ck" className="ck-content">
          <h1 dangerouslySetInnerHTML={{ __html: sanitizedTitle }} />
          <ArticlePrevWrap
            dangerouslySetInnerHTML={{ __html: stripHtml(sanitizedContent) }}
          />
        </ArticleBody>
      ) : null}
    </PreviewArticleContainer>
  );
}
