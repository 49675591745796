import { useState, useEffect, useContext } from "react";
import { ArticleContext, CategoryContext, KBContext } from "../index";
import {
  getArticles,
  getParticularArticle,
} from "../../Service/ArticleService";

export default function ArticleProvider(props) {
  const { categoryData } = useContext(CategoryContext);
  const { setCustomMeta } = useContext(KBContext);

  const [categorySlug, setCategorySlug] = useState(false);
  const [articleSlug, setArticleSlug] = useState(false);

  const [articleData, setArticleData] = useState([]);
  const [selectedArticle, setSelectedArticle] = useState(true);

  useEffect(() => {
    if (categorySlug) {
      setArticleData([]);
      getArticles(categorySlug)
        .then((res) => {
          if (res?.data?.data?.articles?.length > 0) {
            setArticleData(res?.data?.data?.articles);
          } else {
            setArticleData([]);
          }
        })
        .catch((rej) => {
          setArticleData(false);
        });
    }
  }, [categoryData, categorySlug]);

  useEffect(() => {
    if (articleSlug) {
      getParticularArticle(articleSlug)
        .then((res) => {
          setCustomMeta(res?.data?.data?.articleMeta);
          setSelectedArticle(res?.data?.data);
        })
        .catch((rej) => {
          setSelectedArticle(false);
        });
      } else {
        setCustomMeta(null);
      }
    }, [articleSlug]);
    
  return (
    <ArticleContext.Provider
      value={{
        articleData: articleData,
        setArticleData: setArticleData,
        setSelectedArticle,
        selectedArticle,
        categorySlug,
        setCategorySlug,
        articleSlug,
        setArticleSlug,
      }}
    >
      {props.children}
    </ArticleContext.Provider>
  );
}
